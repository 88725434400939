<template>
  <div></div>
</template>
<script>
export default {
  data () {
    return {
      orderCode: ''
    }
  },
  props: { callback: { type: Function } },
  mounted () {
  },
  methods: {
    async pay (amount, attach, description, body, detail, business, vehicle) {
      var serial = window.sessionStorage.getItem(this.SESSION_SERIAL)
      var pd = {
        appid: 'wxa8145a74e5f2b39a',
        amount: amount,
        attach: attach,
        description: description,
        body: body,
        detail: detail,
        business: business,
        openid: serial
      }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/createJsapiPay', this.$qs.stringify(pd))
      if (res.status === '200') {
        var that = this
        this.orderCode = res.data.code
        var param = res.data.param
        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest', param,
          function (rs) {
            if (rs.err_msg === 'get_brand_wcpay_request:ok') {
              that.retrievePayStatus(vehicle)
            }
          })
      }
    },
    async retrievePayStatus (vehicle) {
      var pd = { appid: 'wxa8145a74e5f2b39a', code: this.orderCode }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/retrievePayStatus', this.$qs.stringify(pd))
      if (res.status === '200') {
        var param = { orderCode: this.orderCode }
        this.callback(param, vehicle)
      }
    }
  }
}
</script>
