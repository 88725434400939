<template>
  <div class="page">
    <Login ref="login" :callback="init" />
    <Navbar title="证书明细" :callback="back" type="SMP" />
    <div class="top">
      <div class="image">
        <van-image :src="task.imagePath"></van-image>
      </div>
      <div class="name">{{ task.name }}</div>
      <van-row class="row">
        <van-col span="5" class="type"> 学习方式： </van-col>
        <van-col span="7" class="val"
          ><van-tag :color="COLOR_M" v-if="task.type === 'ONE'"
            >线上</van-tag
          ></van-col
        >
        <van-col span="5" class="time"> 周期时长： </van-col>
        <van-col span="7" class="val">{{ task.duration }}天</van-col>
      </van-row>
      <van-row class="row" v-if="relationList.length > 0">
        <van-col span="5">课程类型：</van-col>
        <van-col span="18"
          ><span v-for="relation in relationList" :key="relation.taskCode"
            ><van-tag
              plain
              :color="COLOR_M"
              v-if="relation.taskCode !== taskCode"
              @click="changeRelation(relation.taskCode)"
              class="item"
              >{{ relation.alias }}</van-tag
            ><van-tag
              :color="COLOR_M"
              v-if="relation.taskCode === taskCode"
              class="item"
              >{{ relation.alias }}</van-tag
            ></span
          ></van-col
        >
      </van-row>
      <!-- <van-row class="row" v-if="certList.length > 0">
        <van-col span="5">证书选择：</van-col>
        <van-col span="17"
          ><van-tag
            plain
            :color="COLOR_M"
            @click="certShow = true"
            style="max-width: 100%"
            ><div class="text-short">{{ cert.name }}</div>
            &nbsp;&nbsp;&nbsp;&nbsp;<van-icon name="arrow-down"
          /></van-tag>
          <van-popup :show="certShow" position="bottom">
            <van-picker
              :columns="certList"
              :columns-field-names="certCustomField"
              @confirm="confirmCert"
              @cancel="certShow = false"
            /> </van-popup
        ></van-col>
      </van-row> -->
      <van-row class="row" v-if="courseList.length > 0">
        <van-col span="5">课程选择：</van-col>
        <van-col span="18"
          ><van-tag plain :color="COLOR_M" @click="courseShow = true"
            >{{ course.name }}&nbsp;&nbsp;&nbsp;&nbsp;<van-icon
              name="arrow-down"
          /></van-tag>
          <van-popup :show="courseShow" position="bottom">
            <van-picker
              :columns="courseList"
              :columns-field-names="courseCustomField"
              @confirm="confirmCourse"
              @cancel="courseShow = false"
            /> </van-popup
        ></van-col>
      </van-row>
      <van-row class="row">
        <van-col span="5">服务模式：</van-col>
        <van-col span="18"
          ><van-tag plain :color="COLOR_M" @click="deliveryShow = true"
            >{{ delivery.name }}&nbsp;&nbsp;&nbsp;&nbsp;<van-icon
              name="arrow-down"
          /></van-tag>
          <van-popup :show="deliveryShow" position="bottom">
            <van-picker
              :columns="deliveryList"
              :columns-field-names="deliverCustomField"
              @confirm="confirmDelivery"
              @cancel="deliveryShow = false"
            /> </van-popup
        ></van-col>
      </van-row>
      <van-row class="row">
        <van-col span="5">课程评分：</van-col>
        <van-col span="16">
          <van-rate v-model="task.score" readonly size="16" :color="COLOR_S1" />
        </van-col>
        <van-col span="8">
          <!-- <van-button
            icon="sign"
            :color="COLOR_S1"
            size="mini"
            @click="signSubmit()"
            >免费试听</van-button
          > -->
        </van-col>
      </van-row>
      <div class="hr"></div>
      <van-row>
        <van-col span="12" class="price"
          >报名价格：<span class="number color-s2"
            >{{ task.price }}元</span
          ></van-col
        >
        <van-col span="12">
          <van-button
            class="btn"
            icon="sign"
            :color="COLOR_S1"
            size="mini"
            @click="signSubmit()"
            >立即报名</van-button
          >
        </van-col>
      </van-row>
      <van-row>
        <van-col span="12" class="price"
          >推荐奖励：<span class="number color-s2">100元</span></van-col
        >
        <van-col span="12">
          <van-button
            class="btn"
            icon="share-o"
            :color="COLOR_M"
            size="mini"
            @click="shareImage"
            >立即推荐</van-button
          >
        </van-col>
      </van-row>
    </div>
    <div class="count">
      <div class="sub-title">课程数据统计</div>
      <van-row>
        <van-col span="8">
          <div class="number color-m">***</div>
          <div class="name">课程数</div>
        </van-col>
        <van-col span="8">
          <div class="number color-m">***</div>
          <div class="name">报名数</div>
        </van-col>
        <van-col span="8">
          <div class="number color-m">***%</div>
          <div class="name">通过率</div>
        </van-col>
      </van-row>
    </div>
    <div class="detail">
      <van-tabs v-model="active" :color="COLOR_M">
        <van-tab title="培训介绍" v-if="detail.task !== ''">
          <div class="cert-detail" v-html="detail.task"></div>
          <van-image
            v-if="detail.task === ''"
            :src="require('../../../../assets/images/train/desc.jpg')"
          ></van-image
        ></van-tab>
        <van-tab title="证书描述" v-if="detail.cert !== ''">
          <div class="cert-detail" v-html="detail.cert"></div>
        </van-tab>
        <van-tab title="发证机构" v-if="detail.institution !== ''"
          ><div class="cert-detail" v-html="detail.institution"></div
        ></van-tab>
        <van-tab title="用户评价"
          ><div class="no-record">
            <van-image
              width="103"
              height="103"
              :src="require('../../../../assets/images/home/no-record.png')"
            ></van-image></div
        ></van-tab>
      </van-tabs>
    </div>
    <van-popup
      :show="shareShow"
      @click-overlay="shareShow = false"
      @click="shareShow = false"
      style="background-color: transparent; width: 100%; height: 100%"
    >
      <img
        class="shareImg"
        :src="require('../../../../assets/images/online/share-top.png')"
      />
    </van-popup>
    <Tabbar active="home" v-if="role !== 'SEL'" />
    <Share ref="share" />
    <Loading :show="loadingShow" />
    <LogVisit
      ref="logVisit"
      module-type="TRA"
      module-label="TRA_DETAIL_INDEX"
    />
    <Pay :callback="payResult" ref="pay" />
  </div>
</template>
<script>
import { Field, Image, Tag, Rate, Tabs, Tab, Popup, Icon, Picker } from 'vant'
import Login from '../../intercept/Login.vue'
import Navbar from '../../common/Navbar.vue'
import Tabbar from '../common/Tabbar.vue'
import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
import LogVisit from '../../common/LogVisit.vue'
import Pay from '../../common/Pay.vue'
export default {
  components: {
    Login: Login,
    Navbar: Navbar,
    Tabbar: Tabbar,
    Loading: Loading,
    Share: Share,
    LogVisit: LogVisit,
    Pay: Pay,
    [Field.name]: Field,
    [Image.name]: Image,
    [Tag.name]: Tag,
    [Rate.name]: Rate,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Picker.name]: Picker
  },
  data () {
    return {
      shareShow: false,
      loadingShow: false,
      loginState: 'N',
      state: 'INT',
      role: 'CLT',
      person: '',
      score: 5,
      code: '',
      taskCode: '',
      task: {},
      detail: { task: '', cert: '', institution: '' },
      certList: [],
      cert: { name: '', code: '' },
      certCustomField: { text: 'name', value: 'code' },
      certShow: false,
      relationList: [{ alias: '', taskCode: '' }],
      deliverCustomField: { text: 'name', value: 'code' },
      deliveryList: [{ name: '', code: '' }],
      delivery: { name: '', code: '' },
      deliveryShow: false,
      courseCustomField: { text: 'name', value: 'code' },
      courseList: [{ text: '', value: '' }],
      course: { name: '', code: '' },
      courseShow: false,
      active: 0
    }
  },
  mounted () {
    var query = this.$route.query
    this.taskCode = query.taskCode
    this.$refs.login.validate()
  },
  methods: {
    init () {
      this.retrieveTaskDetailList()
      this.retrieveTaskCertList()
      this.retrieveDeliveryList()
      this.retrieveCourseList()
      this.retrieveRelationList()
      this.$refs.logVisit.createLog()
      var key = window.const.global.MEMBER_PERSON_MATCH_CHOSE
      var personCode = window.sessionStorage.getItem(key)
      if (personCode !== null && personCode !== undefined && personCode !== '') {
        this.personCode = personCode
        this.state = 'BCK'
        window.sessionStorage.removeItem(key)
        var submitKey = window.const.global.TRAIN_DETAIL_INDEX_SUBMIT
        var submitStr = window.sessionStorage.getItem(submitKey)
        var submit = JSON.parse(submitStr)
        this.course = submit.course
        this.delivery = submit.delivery
        this.task.code = submit.code
        this.task.price = submit.price
        this.payExpense()
      }
    },
    initShare () {
      var title = this.task.promoTitle
      var desc = this.task.promoDesc
      var link = 'https://moc.utopanet.com/train/detail?taskCode=' + this.taskCode
      if (this.role === 'SEL') {
        link = link + '&refereeType=SEL'
      }
      if (this.role === 'CLT') {
        link = link + '&refereeType=CLT'
      }
      var logo = 'https://moc.utopanet.com/static/logo.png'
      this.$refs.share.initParam(title, desc, link, logo)
    },
    async retrieveTask () {
      this.loadingShow = true
      var pd = { code: this.taskCode, deliveryCode: this.delivery.code }
      const { data: res } = await this.$http.post(this.BMS_URL + '/train/taskAPC/retrieveTask', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.task = res.data
        this.initShare()
      }
      this.loadingShow = false
    },
    async retrieveTaskDetailList () {
      this.loadingShow = true
      var pd = { taskCode: this.taskCode }
      var that = this
      const { data: res } = await this.$http.post(this.BMS_URL + '/train/taskAPC/retrieveTaskDetailList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          if (element.type === 'ITD') {
            that.detail.task = element.content
          }
        })
      }
      this.loadingShow = false
    },
    signSubmit () {
      var vad = this.validateToken()
      if (vad) {
        this.$dialog.confirm({
          title: '提示',
          message: '确认立即报名吗？'
        })
          .then(() => {
            // on confirm
            var submit = { course: this.course, delivery: this.delivery, code: this.task.code, price: this.task.price }
            var backKey = window.const.global.MEMBER_PERSON_MATCH_BACK
            var submitKey = window.const.global.TRAIN_DETAIL_INDEX_SUBMIT
            window.sessionStorage.setItem(backKey, window.location.href)
            window.sessionStorage.setItem(submitKey, JSON.stringify(submit))
            this.$router.push({ path: '/mde/member/personMatch', query: { taskCode: this.taskCode, business: 'TRA' } })
          })
      }
    },
    payExpense () {
      var attach = ''
      var description = '培训订单支付'
      var body = '培训订单支付'
      var detail = ''
      var business = this.task.code
      var amount = this.task.price * 100
      var vehicle = ''
      this.$refs.pay.pay(amount, attach, description, body, detail, business, vehicle)
      // this.createOrder()
    },
    payResult (param, vehicle) {
      this.createOrder()
    },
    confirmCourse (val) {
      this.course = val
      this.courseShow = false
    },
    confirmDelivery (val) {
      this.delivery = val
      this.deliveryShow = false
      this.retrieveTask()
    },
    changeRelation (code) {
      this.taskCode = code
      this.init()
    },
    async createOrder () {
      this.loadingShow = true
      var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var referee = window.sessionStorage.getItem(this.SESSION_REFEREE)
      var refereeType = window.sessionStorage.getItem(this.SESSION_REFEREE_TYPE)
      var site = window.sessionStorage.getItem(this.SESSION_SITE)
      var source = window.sessionStorage.getItem(this.SESSION_SOURCE)
      var pd = {
        taskCode: this.taskCode,
        personCode: this.personCode,
        operatorCode: operator,
        sellerCode: seller,
        refereeCode: referee,
        refereeType: refereeType,
        siteCode: site,
        source: source,
        certCode: this.certCode
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/train/order/createOrder', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$dialog.alert({
          title: '提示',
          message: '报名成功,请耐心等待'
        })
      } else {
        this.$dialog.alert({
          title: '提交失败',
          message: res.msg
        })
      }
      this.loadingShow = false
    },
    confirmCert (obj) {
      this.cert = obj
      this.retrieveCert()
      this.certShow = false
    },
    async retrieveTaskCertList () {
      var pd = { taskCode: this.taskCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/train/taskAPC/retrieveTaskCertList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.certList = res.data
        if (res.data.length > 0) {
          this.cert = res.data[0]
          this.retrieveCert()
        }
      }
    },
    async retrieveCert () {
      var pd = { code: this.cert.code }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/cert/retrieveCert', this.$qs.stringify(pd))
      if (res.status === '200') {
        if (res.data.description !== '' && res.data.description !== undefined) {
          this.detail.cert = res.data.description
        }
        var instCode = res.data.instCodes.split(',')[0]
        this.retrieveInstitution(instCode)
      }
    },
    async retrieveInstitution (code) {
      var pd = { code: code }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/institution/retrieveInstitution', this.$qs.stringify(pd))
      if (res.status === '200') {
        if (res.data.description !== '' && res.data.description !== undefined) {
          this.detail.institution = res.data.description
        }
      }
    },
    async retrieveDeliveryList () {
      this.deliveryList = []
      this.delivery = { name: '', code: '' }
      var pd = { taskCode: this.taskCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/overall/task/retrieveTaskDeliveryList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.deliveryList = res.data
        if (this.state === 'INT') {
          if (this.deliveryList.length > 0) {
            this.delivery = this.deliveryList[0]
          }
        }
        this.retrieveTask()
      }
    },
    async retrieveCourseList () {
      this.courseList = []
      this.course = { name: '', code: '' }
      var pd = { taskCode: this.taskCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/train/taskAPC/retrieveTaskCourseList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.courseList = res.data
        if (this.state === 'INT') {
          if (this.courseList.length > 0) {
            this.course = this.courseList[0]
          }
        }
      }
    },
    async retrieveRelationList () {
      var pd = { taskCode: this.taskCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/overall/relation/retrieveTaskRelationList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.relationList = res.data
      }
    },
    async retrieveDetailList () {
      var that = this
      var pd = { certCode: this.certCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/cert/retrieveCertDetailList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          if (element.type === 'EXM') {
            that.certDetail.exam = element.content
            console.log(element.content)
          } else if (element.type === 'FTE') {
            that.certDetail.future = element.content
          } else if (element.type === 'CDT') {
            that.certDetail.condition = element.content
          } else if (element.type === 'SMP') {
            that.certDetail.sample = element.content
          }
        })
      }
    },
    validateToken () {
      var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
      if (token === null) {
        this.$router.push({ path: '/mde/bind' })
        return false
      } else {
        return true
      }
    },
    retHome () {
      this.$router.push('/mde/main/home/train')
    },
    shareImage () {
      this.$router.push({ path: '/mde/train/shareImage', query: { taskCode: this.taskCode } })
    },
    back () {
      this.$router.push({ path: '/mde/train/home' })
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  background-color: #f5f5f5;
}
::v-deep table {
  border: 1px solid #eee;
  th {
    border: 1px solid #eee;
  }
  tr {
    border: 1px solid #eee;
  }
  td {
    border: 1px solid #eee;
  }
}

.title {
  height: 25px;
  line-height: 25px;
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
  color: #fff;
  background: -webkit-gradient(linear, 0 0, 100% 0, from(#00cc99), to(#fff));
}
.top {
  width: 96%;
  margin-left: 2%;
  background-color: #fff;
  padding: 5px 0px;
  margin: 5px auto;
  border-radius: 10px;
  .image {
    margin: 5px 15px;
  }
  .name {
    height: 25px;
    line-height: 25px;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    padding-left: 15px;
  }
  .row {
    height: 25px;
    line-height: 25px;
    color: #aaa;
    text-align: left;
    padding-left: 15px;
    .item {
      margin-right: 5px;
      margin-bottom: 3px;
      padding: 0px 5px;
    }
  }
  .hr {
    width: 96%;
    border-bottom: 1px solid #eee;
    margin: 3px 2%;
  }
  .price {
    height: 35px;
    line-height: 35px;
    color: #aaa;
    text-align: left;
    padding-left: 15px;
    .number {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .btn {
    width: 80%;
    margin-top: 8px;
  }
}
.count {
  width: 96%;
  margin-left: 2%;
  height: 100px;
  background-color: #fff;
  border-radius: 10px;
  .sub-title {
    font-size: 13px;
    font-weight: 600;
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #eee;
  }
  .number {
    margin-top: 15px;
    height: 20px;
    line-height: 20px;
    border-right: 1px solid #eee;
  }
  .name {
    height: 20px;
    line-height: 20px;
    border-right: 1px solid #eee;
    color: #aaa;
  }
}
.detail {
  margin: 5px 2% 60px 2%;
  width: 96%;
  .inst {
    margin: 10px 0px;
    font-size: 14px;
    font-weight: 600;
  }
  .cert-detail {
    text-align: left;
    width: 96%;
    margin-left: 2%;
  }
}
.shareImg {
  width: 90%;
}
</style>
